import {useMutation, useQueryClient} from '@tanstack/vue-query';
import {PAYMENT_MODULES} from "@/constants";

export const checkoutKeys = {
    all: () => ['addresses'],
    list: (page, limit) => [...checkoutKeys.all(), page, limit],
};


export function getCheckout() {
    return useMutation(
        ({payment_method = "", first_time = false, cardDetails = "", reward_option = ""}) => {
            return useNuxtApp().$axios.post("?route=rest/v2/checkout/checkout", {
                agree: "1",
                cardDetails,
                comment: "",
                payment_method,
                first_time,
                reward_option,
                shipping_method: ""
            })
        }
    );
}

export function selectedAddressMutation() {
    return useMutation(
        (address_id = "") => {
            return useNuxtApp().$axios.post("?route=rest/shipping_address/saveAddress", {address_id})
        }
    );
}

export function applyCouponMutation() {
    return useMutation((coupon = "") => {
        return useNuxtApp().$axios.post("?route=rest/cart/coupon", {coupon})
    })
}

export function removeCouponMutation() {
    return useMutation((coupon = "") => {
        return useNuxtApp().$axios.delete("?route=rest/cart/coupon")
    })
}

export function applyVoucherMutation() {
    return useMutation((voucherCode = "") => {
        return useNuxtApp().$axios.post("?route=rest/v3/wallets/rechargeWalletUsingVoucher", {voucherCode})
    })
}

export function getOrderInfoByIdMutation() {
    return useMutation((
            ({id = ""}) => {
                return useNuxtApp().$axios("?route=rest/order/info", {params: {id}})
            }
        )
    )
}

export function getOrderDetailsByIdMutation() {
    const queryClient = useQueryClient();
    return useMutation((
            ({id = ""}) => {
                return useNuxtApp().$axios("?route=rest/order/orders", {
                    params: {id},
                    hideErrorMessage: true
                })
            }
        ),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(['cart']);
            },
        }
    )
}

export function completeCheckoutMutation() {
    return useMutation((
        ({agree = "1", comment = "", payment_method = "", reward_option = "", shipping_method = "flat.flat"}) => {
            return useNuxtApp().$axios.post("?route=rest/confirm/finish", {
                agree,
                comment,
                payment_method,
                reward_option,
                shipping_method
            })
        }
    ))
}

export function tamaraPayMutation() {
    return useMutation((
        () => {
            return useNuxtApp().$axios.post("?route=rest/confirm/apiTamara_PAY")
        }
    ))
}

export function tabbyPayMutation() {
    return useMutation((
        ({payment_type = ""}) => {
            return useNuxtApp().$axios.post("?route=rest/confirm/apiTabby_PAY", {
                payment_type
            })
        }
    ))
}

export function checkoutPayMutation() {
    return useMutation(
        (params = '') => {
            return useNuxtApp().$axios.post("?route=rest/confirm/apiCheckout_PAY", {...params, cardType: params.type})
        }
    );
}

export function stcInitiateMutation() {
    return useMutation((
        ({telephone = "", module = PAYMENT_MODULES.CHECKOUT} = "") => {
            const routePath = module === PAYMENT_MODULES.VOUCHER ? "v3/voucher/apiStc_PAY_initiate" : "confirm/apiStc_PAY_initiate"
            return useNuxtApp().$axios.post(`?route=rest/${routePath}`, {
                telephone
            })
        }
    ))
}

export function stcPayMutation() {
    return useMutation((
        ({order_id = "", order_payment_id = "", otp = "", module = PAYMENT_MODULES.CHECKOUT} = "") => {
            const routePath = module === PAYMENT_MODULES.VOUCHER ? "v3/voucher/apiStc_PAY_verify" : "confirm/apiStc_PAY_verify"
            return useNuxtApp().$axios.post(`?route=rest/${routePath}`, {
                order_id,
                order_payment_id,
                otp
            })
        }
    ))
}

export function appleSessionMutation() {
    return useMutation(
        (validationUrl = '') => {
            return useNuxtApp().$axios.post("?route=rest/confirm/apiApplepay_SessionValidation", {validationUrl})
        }
    );
}

export function addRewardPhoneMutation() {
    return useMutation(
        ({mobile = ''}) => {
            return useNuxtApp().$axios.post("?route=rest/v4/qitaf/addRewardPhone", {mobile})
        }
    );
}

export function removeCardMutation() {
    return useMutation(({token = ''}) => {
        return useNuxtApp().$axios.post("?route=rest/account/removeBankCard", {token})
    })
}

export function cancelRedeemOption() {
    return useMutation(() => {
        return useNuxtApp().$axios.post("?route=rest/v4/loyalty/cancel_redemption")
    })
}